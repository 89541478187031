
import firebase from "firebase";
import 'firebase/messaging';

export const askNotificationPerm = (setTokenFound) => {
    if (!firebase.messaging.isSupported()){
      return;
    }
    const messaging = firebase.messaging();
    return messaging.getToken({vapidKey: 'BD9xvwwxbqS9xCmaAr-JQ6LgwYvfv9QTGszzSY6fXKQjf3gFD7bgeowt5P3DdLamnZrGT_rxbNb8eS0M3H-HJbE'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(null);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }