import { buildProperty, buildSchema } from "@camberi/firecms";

type News = {
    name: string;
    description: string;
    creationDate: Date;
    published: boolean;
    image: string;
    url: string;
}

export const newsSchema = buildSchema<News>({
    name: "Noticia",
    properties: {
        name: {
            title: "Name",
            validation: { 
                required: true,
                max: 18
            },
            dataType: "string"
        },
        description: {
            title: "Description",
            validation: { 
                required: true,
                max: 40
            },
            dataType: "string",
            config: {
                multiline: true
            },
        },
        creationDate: {
            title: "Creation date",
            validation: { required: true },
            dataType: "timestamp"
        },
        published: {
          title: "Published",
          validation: { required: true },
          dataType: "boolean"
        },
        image: buildProperty({
          title: "Image",
          dataType: "string",
          config: {
              storageMeta: {
                  mediaType: "image",
                  storagePath: "news",
                  acceptedFiles: ["image/*"]
              }
          }
        }),
        url: {
            title: "Url",
            dataType: "string",
        },
    }
});