import React, { useEffect, useState } from "react";

import {
    Authenticator,
    buildCollection,
    buildProperty,
    buildSchema,
    CMSApp,
    NavigationBuilder,
    NavigationBuilderProps
} from "@camberi/firecms";

import firebase from "firebase";

import "typeface-rubik";
import "typeface-space-mono";
import { contactSchema } from "./models/contact.model";
import { newsSchema } from "./models/news.model";
import { askNotificationPerm } from "./firebase";

// TODO: Replace with your config
const firebaseConfig = {
    apiKey: "AIzaSyDxpCGFLWoq1kTI5yUE_OMwMaSlm6Ozk4M",
    authDomain: "crownsbakery-754a2.firebaseapp.com",
    projectId: "crownsbakery-754a2",
    storageBucket: "crownsbakery-754a2.appspot.com",
    messagingSenderId: "563782597331",
    appId: "1:563782597331:web:95a8a2e527fcdd8effb226",
};



export default function App() {

    const [isTokenFound, setTokenFound] = useState();

    const onInitFirebase = (config: any) => {
        askNotificationPerm(setTokenFound);
        console.log('Firebase config', config)
    }

    useEffect(() => {
        if(isTokenFound){
            const db = firebase.firestore();
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User logged in already or has just logged in.
                    console.log(user.uid);
                    db.collection("devices").doc(isTokenFound).set({
                        token: isTokenFound,
                        email: user.email
                    })
                        .then(function() {
                            console.log("Document successfully written!");
                        })
                        .catch(function(error) {
                            console.error("Error writing document: ", error);
                        });
                } else {
                  // User not logged in or has just logged out.
                }
              });
            // Save token in user
        }
    }, [isTokenFound])

    const navigation: NavigationBuilder = async ({
                                                     user,
                                                     authController
                                                 }: NavigationBuilderProps) => {

        // This is a fake example of retrieving async data related to the user
        // and storing it in the authController
        const sampleUser = await Promise.resolve({
            name: user?.email,
            roles: ["admin"]
        });
        authController.setExtra(sampleUser);

        return ({
            collections: [
                buildCollection({
                    relativePath: "contact",
                    schema: contactSchema,
                    name: "Contacto",
                    defaultSize: 'xs',
                    initialSort: ["creationDate", "desc"],
                    inlineEditing: false,
                    properties: ['name', 'email', 'phone', 'type', 'status', 'creationDate', 'orderDate', 'resolveDate'],
                    permissions: ({ user, authController }) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    })
                }),
                buildCollection({
                    relativePath: "news",
                    schema: newsSchema,
                    name: "Noticias",
                    defaultSize: 'xs',
                    initialSort: ["creationDate", "desc"],
                    inlineEditing: false,
                    properties: ['name', 'description', 'creationDate', 'published', 'image'],
                    permissions: ({ user, authController }) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    })
                })
            ]
        });
    };

    const myAuthenticator: Authenticator = (user?: firebase.User) => {
        console.log("Allowing access to", user?.email);
        return true;
    };

    return <CMSApp
        name={"CrownsBakery Admin Panel"}
        authentication={myAuthenticator}
        navigation={navigation}
        signInOptions={[firebase.auth.EmailAuthProvider.PROVIDER_ID]}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={onInitFirebase}
    />;
}
