import { buildProperty, buildSchema } from "@camberi/firecms";

type Contact = {
    name: string;
    email: string;
    phone: string;
    creationDate: Date;
    orderDate: Date;
    resolveDate: Date;
    status: string;
    type: string;
    message: string;
    image: string;
    taste: string;
    size: string;
    extras: string []
}

export const contactSchema = buildSchema<Contact>({
    name: "Contact",
    properties: {
        name: {
            title: "Name",
            validation: { required: true },
            dataType: "string",
            columnWidth: 200
        },
        email: {
            title: "Email",
            validation: { required: true },
            dataType: "string",
            columnWidth: 200
        },
        phone: {
            title: "Phone",
            validation: { required: true },
            dataType: "string",
            columnWidth: 200
        },
        creationDate: {
            title: "Creation date",
            dataType: "timestamp"
        },
        orderDate: {
            title: "Order date",
            dataType: "timestamp"
        },
        resolveDate: {
            title: "Resolve date",
            dataType: "timestamp"
        },
        status: {
          title: "Status",
          validation: { required: true },
          dataType: "string",
          config: {
            enumValues: {
                'new': "Nueva",
                'readed': "Leida",
                'resolve': "Completada"
            }
          }
        },
        type: {
          title: "Type",
          validation: { required: true },
          dataType: "string",
          config: {
            enumValues: {
                'general': "General",
                'generic-cake': "Tarta generica",
                'custom-cake': "Tarta personalizada",
                'ask-cupcake': "Cupcake",
                'ask-biscuits': "Galletas",
                'ask-event': "Eventos",
            }
          }
        },
        message: {
          title: "Message",
          validation: { required: true },
          dataType: "string",
          config: {
            multiline: true
          },
        },
        image: buildProperty({
          title: "Image",
          dataType: "string",
          config: {
              storageMeta: {
                  mediaType: "image",
                  storagePath: "uploads",
                  acceptedFiles: ["image/*"]
              }
          }
        }),
        taste: {
          title: "Taste",
          validation: { required: true },
          dataType: "string",
          config: {
            enumValues: {
                'black-oreo': "CHOCOLATE HÚMEDO CON CREMA SUAVE DE NUTELLA Y OREO",
                'red-velvet': "RED VELVET",
                'vainilla-oreo': "VAINILLA CON CREMA SUAVE DE NUTELLA Y OREO",
                'vainilla-queso': "VAINILLA CON CREMA DE QUESO",
                'vainilla-ganacheChocolate': "VAINILLA CON GANACHE DE CHOCOLATE",
                'carrot-cake': "CARROT CAKE",
                'fresa-limon': "FRESA CON CREMA SUAVE DE LIMON",
                'comments': "PREGUNTA POR TU SABOR FAVORITO"
            }
          }
        },
        size: {
          title: "Size",
          validation: { required: true },
          dataType: "string"
        },
        extras: {
            title: "Extras",
            validation: { required: false },
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    enumValues: {
                        'Muñeco': "Muñeco",
                        'Foto impresa': "Foto impresa",
                        'Flores de azucar': "Flores de azucar",
                        'Toppers': "Toppers"
                    }
                }
            }
        }
    }
});